import DefaultEnum from '@/enums/DefaultEnum';
import { apiRequest, defaultHeaders } from './services/apiService';

export default {
    data() {
        return {
            whatsapp: "https://wa.me/" + process.env.VUE_APP_NUMERO_WHATSAPP,
            isClicked: DefaultEnum.NAO,
            loading: DefaultEnum.SIM,
            texto: "",
            logoTipo: process.env.VUE_APP_LOGO,
            DefaultEnum: DefaultEnum,
            planoEscolhido: {
                servicos: []
            },
            lsCliente: this.$secureLS.get('ls_customer') ?? null,
            lsClienteId: this.$secureLS.get('ls_customer').id ?? null,
            lsCotacao: this.$secureLS.get('ls_quotation') ?? null,
            lsCotacaoId: this.$secureLS.get('ls_quotation').id ?? null,
            lsAfiliado: this.$secureLS.get('ls_affiliate') ?? null,
            lsIndicacao: this.$secureLS.get('ls_indication') ?? null,
            lsLastComp: this.$secureLS.get('ls_current_comp') ?? null,
            timerInterval: 0,
        }
    },
    watch: {
    },
    methods: {
        alert(titulo, message, tipo) {
            return this.$swal.fire(
                titulo,
                message,
                tipo,
            );
        },
        confirm(options, callback) {
            options = Object.assign({
              title: "Você tem certeza ?",
              text: "Deseja prosseguir ? Essa ação não poderá ser desfeita.",
              type: "warning",
              showCancelButton: true,
              closeOnConfirm: false,
              showLoaderOnConfirm: true,
              cancelButtonText: "Cancelar",
              confirmButtonText: "Confirmar",
              confirmButtonColor: "#13DA84",
              allowOutsideClick: () => !this.$swal.isLoading(),
              inputValidator: (value) => {
                if (!value) {
                    return 'Campo obrigatório';
                }
            },
            }, options);
            this.$swal(options).then((result) => {
                if (result.dismiss) {
                    // Chama uma função de cancelamento, se definida
                    if (typeof options.onCancel === 'function') {
                      options.onCancel();
                    }
                }
                
                if (result.value && typeof callback === 'function') {
                    callback(result);
                }
            });
        },
        // Método responsável por desabilitar os botões de navegação quando são clicados uma vez
        clicked(){
            if (!this.isClicked) {
                this.isClicked = true;
            }
        },
        // remove caracteres especiais de string
        limpaString(value) {
            if (value) {
                return value.toString().replace(/[^0-9]+/g, '');
            }
        },
        //Validando o retorno do envio
        validarRetorno(titulo, data) {
            var mensagens = [data.message];
            var errors = data.errors || null;

            if (errors != null) {
                for (var index in errors) {
                    mensagens.push(errors[index].join(', '));
                }
            }

            this.$swal.fire(titulo, mensagens.join('<br />'), 'error');
        },
        // converte o fuckin input text de data para formato db
        converteData(date) {
            if (date) {
                let part = date.toString().split('/')
                return `${part[2]}-${part[1]}-${part[0]}`
            }
        },
        validarCpf(cpf) {
            var soma = 0;
            var resto;
            var strCPF = cpf.replace(/[^0-9]+/g, '');
            if (strCPF === '00000000000') {
                this.$swal.fire('Erro ao validar CPF', 'O CPF informado e inválido.', 'error')
                return false;
            }
            for (var i = 1; i <= 9; i++) {
                soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
            }
            resto = soma * 10 % 11;
            if (resto === 10 || resto === 11) {
                resto = 0;
            }
            if (resto !== parseInt(strCPF.substring(9, 10))) {
                this.$swal.fire('Erro ao validar CPF', 'O CPF informado e inválido.', 'error')
                return false;
            }
            soma = 0;
            for (var _i = 1; _i <= 10; _i++) {
                soma = soma + parseInt(strCPF.substring(_i - 1, _i)) * (12 - _i);
            }
            resto = soma * 10 % 11;
            if (resto === 10 || resto === 11) {
                resto = 0;
            }
            if (resto !== parseInt(strCPF.substring(10, 11))) {
                this.$swal.fire('Erro ao validar CPF', 'O CPF informado e inválido.', 'error')
                return false;
            }
            return true;
        },
        iniLoading() {
            this.backToTop()
            this.loading = DefaultEnum.SIM
        },
        stopLoading() {
            this.backToTop()
            this.loading = DefaultEnum.NAO
            
            this.$store.commit('UPDATE_LOADING', DefaultEnum.NAO);
        },
        backToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        isObjectFilled(obj) {
            return Object.values(obj).every(value => value !== null && value !== undefined && value !== '');
        },
        apenasNumeros(valor) {
            return valor.replace(/[^0-9]/g, '');
        },
        removeCaracteresEspeciais(valor) {
            if (!valor) return "";
            // Remove todos os caracteres que não sejam letras (incluindo acentos), números ou espaços
            return valor.replace(/[^A-Za-zÀ-ÿ0-9\s]/g, '');
        },
        // Busca o último componente acessado
        getCurrentComp() {
            return this.$secureLS.get('ls_current_comp').comp;
        },
        // Salva o último componente acessado
        // Método resposável pela navegação
        setCurrentComp(comp, id) { 
            if(comp == this.getCurrentComp())return;
            this.$store.dispatch('updateComponent', {
                comp: comp,
                id: id
            });
        },
        // Faz a navegação entre os componentes
        // Salva o component, id  e query na rota caso existam
        navegacao(componente, id, query){
            this.clicked()
            this.iniLoading()
            this.$router.push({ 
                path: `/${componente}/${id || ''}`,
                query: query ? { afiliado: query } : undefined
            }).catch(err => {
                if (err.name !== 'NavigationDuplicated') throw err;
            });

            this.setCurrentComp(componente, id)
        },
        // Limpa localstorage
        clearLS(){
            this.$secureLS.set('ls_choosed_plan', null);
            this.$secureLS.set('ls_quotation', null);
            this.$secureLS.set('ls_customer', null);
            this.$secureLS.set('ls_affiliate', null);
            this.$secureLS.set('ls_indication', null);
        },
        // Limpa a url
        clearRoute(){
            this.$router.replace({ 
                path: this.$route.path, 
                query: {}, 
                params: {} 
            }).catch(err => {
                if (err.name !== 'NavigationDuplicated') throw err;
            });
        },
        // Verifica se existe componente na rota
        getUrl(){
            return this.$route.path.split('/').filter(Boolean); // Divide e remove strings vazias
        },
        // Busca o componente da url
        getCompUrl(){
            let component = null;

            // Verifica se há ao menos um segmento após o primeiro "/"
            if (this.getUrl().length >= 1) {
                component = this.getUrl()[0];
            }

            return component
        },
        // Pega o id da url caso ele exista.
        // O id vem depois da rota(componente), ficando na segunda posição
        getIdUrl(){
            let id = null;

            if (this.getUrl().length >= 1 && this.getUrl()[1]) {
                id = this.getUrl()[1];
            }

            return id
        },
        async confirmarCPF(){
            const compUrl = this.getCompUrl()
            const idUrl = this.getIdUrl()

            this.confirm({
                titleText: 'Confirme seus dados',
                text: 'Essa cotação está em andamento. Confirme seus dados para continuar.',
                html: `
                    <input id="telefone" class="swal2-input" placeholder="Telefone">
                    <input id="cpf_cnpj" class="swal2-input" placeholder="CPF/CNPJ">
                `,
                inputPlaceholder: 'Insira o seu CPF',
                allowOutsideClick: false,
                closeOnConfirm: false,
                preConfirm: async (result) => {
                    console.log(result);
                    const data = {
                        "telefone": document.getElementById("telefone").value,
                        "cpf_cnpj": document.getElementById("cpf_cnpj").value
                    }

                    await apiRequest({
                        url: process.env.VUE_APP_FIVE_API + "veiculo-cotacao/checkout/confirmar-cpf",
                        headers: {
                            ...defaultHeaders.authFive()
                        },
                        method: DefaultEnum.METHODS.POST,
                        params: data,
                        onSuccess: (response) => {
                            if(response.data.data.result != DefaultEnum.SIM){
                                throw new Error('Não foi possível verificar seus dados. Tente novamente');
                            }

                            this.navegacao(compUrl, idUrl)
                            this.alert('Tudo certo', 'Prossiga com sua cotação normalmente', 'success')
                            return true
                        },
                        onError: (error) => {
                            this.$swal.showValidationMessage(error.message)
                            return this.navegacao(DefaultEnum.COMPONENTS.INICIO)
                        }
                    })
                },
                onCancel: () => {
                    console.log('cancelando');
                    return this.navegacao(DefaultEnum.COMPONENTS.INICIO)
                }
            });
        },
        // Faz a verificação do código do afiliado passado na url
        async verificaAfiliadoQuery(){
            const urlParams = new URLSearchParams(window.location.search);
            const afiliado = urlParams.get(DefaultEnum.AFILIADO);

            if(!afiliado)return;
            if(afiliado && this.lsAfiliado && afiliado == this.lsAfiliado)return;

            return this.setAfiliado(afiliado);
        },
        // Busca e seta o afiliado caso estiver apto
        async setAfiliado(identificador){

            await apiRequest({
                // Verificar
                url: process.env.VUE_APP_FIVE_API + "afiliado/codigo/" + identificador,
                headers: {
                    ...defaultHeaders.json,
                    ...defaultHeaders.authFive()
                },
                onSuccess: (response) => {
                    if(response){
                        this.$secureLS.set('ls_affiliate', response.data.data.result);
                        this.alert('Tudo certo!', 'Afiliado localizado com sucesso! Prossiga com a cotação normalmente.', 'success');
                    } else {
                        this.alert('Ops!', 'Não foi possível localizar o afiliado. Verifique as informações e tente novamente.', 'warning');
                    }
                },
                onError: () => {
                    this.$swal.fire('Opss!', 'Ocorreu um erro inesperado. Confira a identificação do afiliado e tente novamente.', 'error');
                }
            })
        },
        async verificaCliente(idParam = null){ 
            const idUrl = this.getIdUrl ? this.getIdUrl() : this.lsClienteId;
            const idPesquisa = idParam ?? idUrl;

            return await apiRequest({
                url: process.env.VUE_APP_FIVE_API + `cliente/${idPesquisa}`,
                headers: {
                    ...defaultHeaders.json,
                    ...defaultHeaders.authFive()
                },
                onSuccess: (response) => {
                    this.$secureLS.set('ls_customer', response.data.data);
                    return response.data.data
                },
                onError: (error) => {
                    this.$swal.fire('Opss!', 'Não foi possível localizar seus dados de cliente.', 'error');
                    this.navegacao(DefaultEnum.COMPONENTS.INICIO)
                    throw error;
                }
            })
        },
        // Verifica se a cotação existe
        async verificaCotacao(idParam = null) {
            const idUrl = this.getIdUrl ? this.getIdUrl() : this.lsCotacaoId;
            const idPesquisa = idParam ?? idUrl;
          
            return await apiRequest({
                url: process.env.VUE_APP_FIVE_API + `veiculo-cotacao/cotacao/${idPesquisa}`,
                headers: {
                    ...defaultHeaders.json,
                    ...defaultHeaders.authFive()
                },
                onSuccess: (response) => {
                    this.$secureLS.set('ls_quotation', response.data.data);
                    return response.data.data;
                },
                onError: (error) => {
                    this.$swal.fire('Opss!', 'Não foi possível localizar sua cotação.', 'error');
                    if(this.intervalCotacao){
                        clearInterval(this.intervalCotacao);
                    }
                    this.navegacao(DefaultEnum.COMPONENTS.INICIO);
                    throw error;
                }
            })
        },  
        async getBoletoIntegra(id) {
            await apiRequest({
                url: process.env.VUE_APP_FIVE_API + 'pagamento/integra/transacao/get-boleto/' + id,
                headers: {
                    ...defaultHeaders.json,
                    ...defaultHeaders.authFive()
                },
                onSuccess: (response) => {
                    return response;
                },
            })
        }
    },
};