import DefaultEnum from '@/enums/DefaultEnum';
import axios from 'axios';

const defaultHeaders = {
  json: {
    "Accept": "application/json",
    "Content-Type": "application/json",
  },
  urlencoded: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  authFive: () => ({
    "Authorization":  "Bearer " + process.env.VUE_APP_FIVE_TOKEN,
  }),
  auth: (tipo = "Bearer", token = null) => ({
    "Authorization": tipo + ' ' + token,
  }),
  file: {
    "Accept": "application/pdf",
  },
};

const apiRequest = async ({ 
  url, 
  method = DefaultEnum.METHODS.GET, 
  data = null, 
  params = {}, 
  headers = {}, 
  onSuccess, 
  onError, 
  onFinally 
}) => {
  try {
    const response = await axios({
      url,
      method,
      data,
      params,
      headers: { ...defaultHeaders.json, ...headers },
    });

    if (onSuccess) {
      return onSuccess(response);
    }
    return response.data;
  } catch (error) {
    if (onError) {
      return onError(error);
    }
    throw error;
  } finally {
    if (onFinally) {
      onFinally();
    }
  }
};

export { apiRequest, defaultHeaders };
