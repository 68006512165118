import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
export const router = new VueRouter({
  mode: 'history',
  path: '/:component?/:id?',
  component: () => import('@/views/BaseView.vue')
})


export default router