<template>

    <div class="row justify-content-center">
        <div class="col-12">
            <!-- Stepper -->
            <StepperComp />
            <!-- / Stepper -->
            
            <!-- Loading -->
            <LoadingDivComp v-show="loading" />
            <!-- Loading -->

            <div class="mt-3 w-70 mb-1" v-show="!loading">
                <div id="iniciar-vistoria" v-show="etapaVistoria == 'aguardando'">
                    <h5 class="text-dark font-weight-bold text-center">
                        Legal! <br> Vamos iniciar sua vistoria.
                    </h5>

                    <img class="" src="/assets/svg/vistoria.svg" alt="" width="80px" height="250px"><br><br>
                    <a v-show="linkVistoria" :href="linkVistoria || linkVistoriaLs" class="link-vistoria button" target="_blank">Clique aqui para iniciar sua vistoria</a>
                </div>

                <div id="aguardando-vistoria" v-show="etapaVistoria == 'iniciado'">
                    <h5 class="font-weight-bold">Sua vistoria já foi iniciada!</h5>
                    <small class="font-weight-bold">Finalize-a e volte para esta tela para prosseguir para a última etapa.</small>
    
                    <svg style="margin: auto; background: transparent; display: block; shape-rendering: auto;" width="100px" height="300px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <circle cx="50" cy="50" fill="none" stroke="var(--cor-primaria)" stroke-width="2" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="2s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                        </circle>
                    </svg>

                    <a v-if="linkVistoria" :href="linkVistoria" class="link-vistoria button" target="_blank">Clique aqui para iniciar sua vistoria</a>
                </div>
                

                <div id="vistoria-reprovada" v-show="etapaVistoria == 'reprovada'">
                    <h5 class="font-weight-bold">Sua vistoria foi reprovada!</h5>

                    <img class="" src="/assets/svg/ops.svg" alt="" width="80px" height="250px"><br><br>
                </div>

                <div id="vistoria-finalizada" v-show="etapaVistoria == 'finalizada'">
                    <h5 class="font-weight-bold">Sua vistoria foi finalizada!</h5>
                    <h6>Prossiga para a última etapa.</h6>

                    <img class="" src="/assets/svg/check.svg" alt="" width="150px" height="350px"><br><br>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-between w-100 mt-5 px-3">
            <!--  -->
            <button :disabled="this.isClicked" :class="{ 'clicked': isClicked }" @click="back()" type="button" name="prev" class="prev left">
                <i class="fa-solid fa-arrow-left-long fa-1x"></i> 
            </button>

            <button v-if="etapaVistoria == DefaultEnum.VISTORIA.ETAPAS.FINALIZADA" :disabled="this.isClicked" :class="{ 'clicked': isClicked }" @click="nextpage()" type="button" name="next" class="next right" >
                <i class="fa-solid fa-arrow-right-long fa-1x"></i>
            </button>
            <!--  -->
        </div>

    </div>
</template>

<script>
import { apiRequest, defaultHeaders } from '@/services/apiService';
import LoadingDivComp from '../parts/LoadingDivComp.vue';
import DefaultEnum from '@/enums/DefaultEnum';

export default {
    components: { LoadingDivComp },
	props: {},

    watch: {
        etapaVistoria(novoValor){
            if(novoValor == DefaultEnum.VISTORIA.ETAPAS.INICIADO){
                this.stopLoading()
            }

            if(novoValor == DefaultEnum.VISTORIA.ETAPAS.AGUARDANDO || novoValor == DefaultEnum.VISTORIA.ETAPAS.FINALIZADA){
                this.stopLoading()
                clearInterval(this.intervalVistoria);
            }            
        }
    },

    data() {
        return {
            linkVistoria: "",
            linkVistoriaLs: this.$secureLS.get('ls_url_contrato'),
            etapaVistoria: "",
            intervalVistoria: null,
		}
	},
    
	methods: {
        back() {
            clearInterval(this.intervalVistoria);
            this.navegacao(DefaultEnum.COMPONENTS.PAGAMENTO, this.lsCotacaoId);
        },
        nextpage() {
            clearInterval(this.intervalVistoria);
            setTimeout(() => {
                this.navegacao(DefaultEnum.COMPONENTS.CONTRATO, this.lsCotacaoId);
            }, 1000);
        },
        async getVistoria(){
            return await apiRequest({
                url: process.env.VUE_APP_FIVE_API + 'veiculo-cotacao/cotacao/' + this.lsCotacaoId + '/vistoria',
                headers: {
                    ...defaultHeaders.authFive()
                },
                onSuccess: (response) => {
                    return response.data.data;
                },
                onError: () => {
                    if(this.intervalVistoria){
                        clearInterval(this.intervalVistoria);
                    }
                    this.stopLoading()
                    this.$swal.fire('Opss!', 'Não foi possível localizar sua vistoria. Tente novamente.', 'error');
                }
            })
        },
        async verificaVistoria(){
            this.intervalVistoria = setInterval(async() => {
                await this.getVistoria().then((response) => {
                    if(response.codigo_acesso){
                        this.linkVistoria = process.env.VUE_APP_DEFAULT_URL + "cotacao/" + response.codigo_acesso + "/documento";
                    }

                    this.etapaVistoria = response.etapa;
                });

            }, 10000 );
        }
	},

    async mounted() {
        this.iniLoading()
        await this.verificaVistoria();
        await this.verificaCotacao().then(data => {
            if (data.cotacao_pago != 1) {
                this.navegacao(DefaultEnum.COMPONENTS.PAGAMENTO, this.lsCotacaoId)
            }
        });
        
    },
}
</script>

<style scoped>
.link-vistoria {
    cursor: pointer;
    color: #FFF;
}
.link-vistoria:hover{
    text-decoration: none;
}
</style>
