const DefaultEnum = Object.freeze({
    MANUAL: "manual",
    PLACA: "placa",
    SIM: true,
    NAO: false,
    PIX: "pix",
    CARTAO_CREDITO: "cartao_credito",
    BOLETO: "boleto",
    CARTAO_SUCESSO: "succeeded",
    CARTAO_PAGO: "Pago",
    FISICA: "fisica",
    JURIDICA: "juridica",
    CLIENTE: "cliente",
    COTACAO: "cotacao",
    AFILIADO: "afiliado",
    AVANCAR: "avancar",
    VOLTAR: "voltar",
    VISTORIA: {
        ETAPAS: {
            INICIADO: "iniciado",
            AGUARDANDO: "aguardando",
            FINALIZADA: "finalizada",
        }
    },
    CONTRATO: {
        ETAPAS: {
            ERRO: "erro",
            SUCESSO: "sucesso",
            VERIFICANDO: "verificando",
        }
    },
    COMPONENTS: {
        INICIO: "inicio",
        VEICULO: "veiculo",
        PLANOS: "planos",
        BENEFICIOS: "beneficios",
        CLIENTE: "cliente",
        PAGAMENTO: "pagamento",
        VISTORIA: "vistoria",
        CONTRATO: "contrato",
    },
    METHODS: {
        POST: "post",
        GET: "get",
        PUT: "put",
        DELETE: "delete",
        PATCH: "patch"
    },
});

export default DefaultEnum;