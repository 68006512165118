<template>
  <div id="app">
    <base-view-vue></base-view-vue>

    <!-- <router-view/> -->
  </div>
</template>

<script>
import BaseViewVue from './views/BaseView.vue';
import '../public/assets/css/font-awesome-6.4.2/all.css';
import '../public/assets/css/bootstrap.min.css';
import '../public/assets/css/bootstrap-grid.min.css';
import '../public/assets/fonts/stylesheet.css';
import '../public/dist/app.css';
// import 'dist/select2/select2.min.css';

const root_css = process.env.VUE_APP_ROOT_CSS;
const titulo = process.env.VUE_APP_TITULO;
const favicon = process.env.VUE_APP_FAVICON;

export default {
  components: {
    BaseViewVue
  }, 
  mounted() {
    const link = document.createElement('link');
    link.rel = 'stylesheet';

    if (root_css) {
      link.href = `../dist/${root_css}.css`;
      document.head.appendChild(link);
    } else {
      link.href = '../dist/padrao.css';
      document.head.appendChild(link);
    }

    if(titulo){
      document.title = titulo;
    }

    if(favicon){
      let link = document.createElement('link');
      link.id = 'favicon';
      link.rel = 'icon';
      link.href = favicon;

      document.head.appendChild(link);
    }

  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>