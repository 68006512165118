<template>
            
    <div class="row no-gutters d-flex justify-content-around">
        <div class="col-12 col-lg-4 d-none d-md-flex align-items-top justify-content-center mt-5">
            <h2 class="text-white font-weight-bold mt-5">
                Proteja seu veículo <br> com a tranquilidade que você merece. <br><br>
                Solicite uma cotação hoje mesmo e garanta a segurança do seu patrimônio.
            </h2>
            <!-- <img src="/assets/svg/find-plan.svg" alt="" width="250px" height="250px"> -->
        </div>
        <div class="col-12 col-lg-4 d-md-none align-items-center justify-content-center mt-5">
            <h3 class="text-white font-weight-bold px-3">
                Legal, vamos começar sua cotação.
            </h3>
        </div>
        
        <div class="col-12 col-lg-5 mb-5 p-3">
            <div class="box-container mt-3">

                <!-- Stepper -->
                <StepperComp />
                <!-- / Stepper -->

                <div class="row no-gutters">
                    <div class="col-12 col-lg-10 text-left">
                        <div class="input-container">
                            <input id="nome_completo" v-model="cliente.nome_completo" class="input" type="text" placeholder=" " @input="cliente.nome_completo = removeCaracteresEspeciais(cliente.nome_completo)"/>
                            <label for="nome_completo" name="nome_completo" class="placeholder">
                                Nome completo <span class="text-danger">*</span>
                            </label>
                            <small class="text-danger obrigatorio pl-3" v-if="$v.cliente.nome_completo.$error">O campo nome é obrigatório</small>
                        </div>
                        <div class="input-container">
                            <input id="email" v-model="cliente.email" class="input" type="email" placeholder=" " autocomplete="on" />
                            <label for="email" name="email" class="placeholder">E-mail <span class="text-danger">*</span></label>
                            <small class="text-danger obrigatorio pl-3" v-if="$v.cliente.email.$error">O campo email é obrigatório</small>
                        </div>
                        <div class="input-container">
                            <input id="telefone_principal" v-model="cliente.telefone_principal" class="input phone_with_ddd" type="tel" placeholder=" "
                                v-mask="'55 (##) # #### ####'" />
                            <label for="telefone_principal" name="telefone_principal" class="placeholder">Telefone principal <span class="text-danger">*</span></label>
                            <small class="text-danger obrigatorio pl-3" v-if="$v.cliente.telefone_principal.$error">O campo telefone é obrigatório</small>
                        </div>
                    </div>

                    <div class="detalhes-form text-left mt-4">
                        <p>Campos destacados com <span style="color: red; font-size: 15px;"> * </span> são obrigatórios. <br>
                            Ao preencher e enviar este formulário, você autoriza contato por meio de SMS, ligações, e-mail ou whatsapp.
                        </p>

                        <div class="input-container text-left">
                            <small class="text-danger text-left obrigatorio" v-if="$v.termo.$error">Você precisa aceitar os termos</small>
                            <label class="label-termos"> 
                                <label for="termo" name="termo"></label>
                                <input id="termo" name="termo" v-model="termo" type="checkbox" :value="termo">
                                &nbsp;Li e concordo com os
                                <a :href="linkTermo" target="_blank">Termos de aceite</a> e 
                                <a :href="linkPolitica" target="_blank">Política de privacidade</a>
                            </label>
                        </div>
                    </div>
                </div>

                <button :disabled="this.isClicked" :class="{ 'clicked': isClicked }" @click="salvaBuscaCliente()" class="next right">
                    Próximo <i class="fa-solid fa-arrow-right-long"></i>
                </button>
            </div>
        </div>
    </div>
        
</template>

<script>
import DefaultEnum from '@/enums/DefaultEnum';
import { apiRequest, defaultHeaders } from '@/services/apiService';
import { required, sameAs }  from 'vuelidate/lib/validators';

export default {
    components: {
	},

    computed: {
        dadosCliente() {
            return {
                "nome": this.removeCaracteresEspeciais(this.cliente.nome_completo),
                "cpf_cnpj": this.limpaString(this.cliente.cpf),
                "email": this.cliente.email,
                'origem_cadastro': process.env.VUE_APP_ORIGEM_CADASTRO,
                "telefone": this.limpaString(this.cliente.telefone_principal),
                "origem": process.env.VUE_APP_ORIGEM,
                "afiliado": this.lsAfiliado.codigo_afiliado ?? null,
                "afiliado_id": this.lsAfiliado.id ?? null
            }
        },
    },

    data() {
        return {
            cliente: {
                id: null,
                nome_completo: null,
                email: null,
                telefone_principal: null,
            },
            termo: false,
            linkTermo: process.env.VUE_APP_LINK_TERMO_ACEITE,
            linkPolitica: process.env.VUE_APP_LINK_POLITICA_PRIVACIDADE,
        }
    },
    validations(){
        return {
            cliente: {
                nome_completo: { required },
                email: { required },
                telefone_principal: { required }
            },
            termo: { sameAs: sameAs( () => true) }
        }
    },
    methods: {
        // ETAPA 1 - cria ou busca cliente
        async salvaBuscaCliente() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.isClicked = false;
                this.alert('', 'Preencha todos os campos corretamente', 'warning')
                return;
            }

            this.clicked();

            await apiRequest({
                url: process.env.VUE_APP_FIVE_API + 'prospeccao/prospeccao/' + process.env.VUE_APP_ORIGEM,
                method: DefaultEnum.METHODS.POST,
                data: this.dadosCliente,
                headers: {
                    ...defaultHeaders.authFive()
                },
                onSuccess: (response) => {
                    this.cliente = response.data.cliente;
                    this.$secureLS.set('ls_customer', response.data.cliente);
                    this.$secureLS.set('ls_indication', response.data.cliente.indicacao);

                    // Next
                    this.navegacao(DefaultEnum.COMPONENTS.VEICULO, response.data.cliente.id);
                },
                onError: (error) => {
                    this.isClicked = false;
                    this.validarRetorno('', error.response.data);
                }
            });

        },
    },

    created() {
    },

}
</script>

<style scoped>
.detalhes-form {
    font-size: 11px;
}

input[type=checkbox] {
    height: 11px;
}

.label-termos {
    font-size: 13px;
}
.faq-section {
    height: 70vh;
    width: 100%;
}

</style>