import Vue from 'vue'
import App from './App.vue'
import axios from "axios";
import VueTheMask from "vue-the-mask";
import VueSweetalert2 from 'vue-sweetalert2';
import moment from 'moment';
import mixin from './mixin'
import Loader from 'vue-ui-preloader';
import router from './router'
import store from './store'
import SecureLS from "secure-ls";
import StepperComp from './components/parts/StepperComp.vue';
import DefaultEnum from "@/enums/DefaultEnum";
import VueGtm from '@gtm-support/vue2-gtm';
import Vuelidate from 'vuelidate'
import 'sweetalert2/dist/sweetalert2.min.css';
import '../public/assets/css/credit-card-form.css';

axios.defaults.baseURL = process.env.VUE_APP_DEFAULT_URL + 'api/';
await axios.get('v1/veiculo-cotacao/checkout/gtm')
.then((result) => {
  const gtmId = result.data.data.gtm ?? null;

  if(gtmId){
    Vue.use(VueGtm, {
      id: result.data.data.gtm, 
      defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results
      compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie
      debug: false, // Whether or not display console logs debugs (optional)
      loadScript: true, // Whether or not to load the GTM Script
      vueRouter: router, // Pass the router instance to automatically sync with router
      trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    });

  }
})

const secureLS = new SecureLS({
  // encodingType: '',
  encodingType: 'aes',
  encryptionSecret: process.env.VUE_APP_ENCRYPTION_LS_KEY,
  isCompression: false
});

Vue.component('StepperComp', StepperComp)
Vue.mixin(mixin)
Vue.use(VueTheMask)
Vue.use(VueSweetalert2)
Vue.use(Loader)
Vue.use(DefaultEnum)
Vue.use(Vuelidate)
Vue.use({ install(Vue) { Vue.prototype.$http = axios; Vue.prototype.$secureLS = secureLS; }});
Vue.filter('dataCarencia', function (value) {
  if (value) {
    return moment().add(value, 'months').add(1, 'days').format('DD/MM/YYYY');
  }
});
Vue.filter('toCurrency', function (value, showSymbol = true) {
  if (typeof value !== "number") {
    return value;
  }
  var options = {
    style: 'currency',
    currency: 'BRL'
  };

  var formatter = new Intl.NumberFormat('pt-BR', options);
  if (!showSymbol) {
    return formatter.format(value).replace("R$", "");
  }
  return formatter.format(value);
});

new Vue({
  el: "#app",
  router,
  store,
  render: h => h(App)
});