<template>
    <div class="root bg-section">
        <!-- Header -->
        <partHeader />

        <!-- Start -->
        <div class="main-container" v-if="this.getStep.current === DefaultEnum.COMPONENTS.INICIO ">
            <inicio />
        </div>

        <!-- Components -->
        <div class="main-container px-3" v-if="this.getStep.current !== DefaultEnum.COMPONENTS.INICIO ">

            <div class="row justify-content-center">
                <div class="col-sm-12 col-md-8 mt-5 mt-5 mb-5">
                    <div class="box-container mt-3">
                        
                        <component :is="this.getStep.current" @component-loaded="onComponentLoaded"/>

                    </div>
                </div>
            </div>

        </div>

        <fab
        :main-icon="mainIcon"
        :icon-size="iconSize"
        :position="position"
        :bg-color="bgColor"
        :actions="fabActions"
        :enable-rotation=false
        @novaCotacao="novaCotacao"
        @codigoAfiliado="codigoAfiliado"
        @openWhatsapp="openWhatsapp"
        ></fab>

        <!-- Footer -->
        <partFooter />
    </div>
</template>
  
<script>
/* eslint-disable */
// Base
import partHeader from "@/components/parts/HeaderComp.vue";
import partFooter from "@/components/parts/FooterComp.vue";
// Auxiliares
import LoadingComp from "@/components/parts/LoadingComp.vue";
import LoadingDivComp from "@/components/parts/LoadingDivComp.vue";
import { mapGetters } from 'vuex';
import mixin from '@/mixin';
// Telas/Componentes
import inicio from "@/components/cliente/InicioComp.vue";
import veiculo from '@/components/veiculo/VeiculoComp.vue';
import planos from '@/components/plano/PlanosComp.vue';
import beneficios from '@/components/plano/BeneficiosComp.vue';
import cliente from '@/components/cliente/ClienteCompleto.vue';
import pagamento from "@/components/pagamento/PagamentoComp.vue";
import vistoria from "@/components/vistoria/VistoriaComp.vue";
import contrato from "@/components/contrato/ContratoComp.vue";
import fab from 'vue-fab'
import DefaultEnum from "@/enums/DefaultEnum";

export default {
    components: {
        partHeader,
        partFooter,
        LoadingComp,
        LoadingDivComp,
        fab,
        inicio,
        veiculo,
        planos,
        beneficios,
        cliente,
        pagamento,
        vistoria,
        contrato
    },
    mixins: mixin,
    data() {
        return {
            mainIcon: "help",
            iconSize: "small",
            position: "bottom-right",
            bgColor: "var(--cor-primaria)",
            fabActions: [
              {
                  name: 'novaCotacao',
                  icon: 'replay',
                  tooltip: 'Nova cotação'
              },
              {
                  name: 'codigoAfiliado',
                  icon: 'contacts',
                  tooltip: 'Afiliado'
              },
              {
                  name: 'openWhatsapp',
                  icon: 'call',
                  tooltip: 'Fale com um consultor'
              }
          ]
        }
    },
    computed: {
        ...mapGetters({
            getStep: 'getStep',
            
        }),
    },
    methods: {
        novaCotacao(){
            this.confirm({
                title: 'Deseja iniciar uma nova cotacao ?',
                text: 'Todos os dados preenchidos até aqui seram perdidos. Tem certeza ?',
                type: 'warning',
                cancelButtonText: 'Permanecer na atual',
                confirmButtonText: 'Começar nova cotação',
            },() => {
                this.clearLS();
                this.clearRoute();
                this.setCurrentComp(DefaultEnum.COMPONENTS.INICIO); 
            }
        )},
        codigoAfiliado() {
            this.confirm({
                titleText: 'Digite o código do afiliado',
                text: '',
                input: 'text',
                inputPlaceholder: 'Insira o código aqui',
                inputLabel: 'Deseja fazer sua cotação usando esse código de afiliado?',
                showLoaderOnConfirm: true,
                showCancelButton: false,
                allowOutsideClick: () => !this.$swal.isLoading(),
                inputValidator: (value) => {
                    if (!value) {
                        return 'Você precisa digitar um código!';
                    }
                },
                preConfirm: async (result) => {
                    try {
                        const indicacao = await this.setAfiliado(result);
                        if (indicacao && indicacao.data.code === 200) {
                            this.alert('Tudo certo!', 'Afiliado localizado com sucesso! Prossiga com a cotação normalmente.', 'success');
                        } 
                        
                    } catch (error) {
                        this.showValidationMessage('Erro', 'Houve um problema ao processar sua solicitação.', 'error');
                    }
                },
            })
        },
        //analisar se essa função pode ser usada globalmente
        openWhatsapp(){
            const newTab = window.open(this.whatsapp, '_blank');
            if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
                alert('O bloqueador de pop-ups está ativado. Permita pop-ups para abrir o link.')
            }
        },
        onComponentLoaded() {
          this.isClicked = false
        },
        async verificaNavegacao() {
            const compUrl = this.getCompUrl();
            const idUrl = this.getIdUrl();
            const lsComp = this.lsLastComp;

            // URL NÃO PRENCHIDA
            // Navegação inicial
            if ((!compUrl && !idUrl) && !lsComp) {
                return this.navegacao(DefaultEnum.COMPONENTS.INICIO);
            }

            // Sem url preenchida
            // Com localstorage e diferente da página inicial
            if ((!compUrl && !idUrl) && (lsComp.comp && lsComp.id) && lsComp.comp != DefaultEnum.COMPONENTS.INICIO) {
                return this.navegacao(lsComp.comp, lsComp.id);
            }
            // -----------------------


            // URL PREENCHIDA
            // Sem LocalStorage
            if ((compUrl && idUrl) && !lsComp) {
                return this.confirmarCPF();
            }

            // Com LocalStorage 
            // ID URL == a cotação id
            if (((compUrl && idUrl) && lsComp && idUrl === lsComp.id)) {
                return this.navegacao(lsComp.comp, lsComp.id);
            }

            if (((compUrl && idUrl) && lsComp && idUrl != lsComp.id)) {
                return this.confirmarCPF();
            }

            // Navegação para o componente inicial
            if (compUrl === DefaultEnum.COMPONENTS.INICIO) {
                return this.navegacao(DefaultEnum.COMPONENTS.INICIO);
            }
            // -----------------------

            this.verificaAfiliadoQuery();
            return this.navegacao(DefaultEnum.COMPONENTS.INICIO);
        }
    },
    created(){
    },
    async mounted(){
        await this.verificaNavegacao()
        await this.verificaAfiliadoQuery()
    },
    
}
</script>
<style>
.fab-main{
    padding: 23px !important;
}
</style>