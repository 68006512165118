<template>
    <div class="row">



        <!-- Plans -->
        <div class="col-12 w-100">
            <!-- Stepper -->
            <StepperComp />
            <!-- / Stepper -->

            <!-- Loading -->
            <LoadingDivComp v-if="loading" />
            <!-- Loading -->

            <h5 class="text-dark font-weight-bold text-center" v-show="this.planos.length != 0">
                Escolha seu plano
            </h5>

            <div class="row pb-3" v-show="this.planos.length != 0">
                <div class="descricao-color col-12 mt-2 text-left pl-4">
                    <h6>
                        Olá, <strong class="exibir-fullname">
                        {{ 
                            (this.lsCliente.nome_razao || this.$secureLS.get('ls_quotation').veiculo.cliente.nome_completo) ?? ''
                        }}</strong>
                    </h6>

                    
                    <span v-if="this.veiculo_modelo != null">Esta é uma cotação para o seu veículo:<br> <strong>{{ this.veiculo_modelo }}</strong></span><br>
                    <span>Temos <strong>{{ this.planos.length ?? 0 }}</strong> planos disponíveis para você.</span>
                </div>


                <!-- Slider main container -->
                <div ref="swiper" class="swiper">
                    <!-- If we need pagination -->
                    <div class="swiper-pagination"></div>

                    <!-- If we need navigation buttons -->
                    <div class="swiper-button-prev"></div>
                    <div class="swiper-button-next"></div>

                    <!-- If we need scrollbar -->
                    <div class="swiper-scrollbar"></div>

                    <!-- Additional required wrapper -->
                    <div class="swiper-wrapper pb-3">

                        <!-- Swiper-slide -->
                        <div class="swiper-slide" v-for="(item, index) in sortedPropostas" :key="item.itself">
                            <div class="card plano-card mt-5">
                                <div class="plano-opcao">
                                    <span class="text-white font-weight-bold">Plano {{ index + 1 }}</span>
                                </div>

                                <div class="card-header text-left">
                                    <h6 class="font-weight-bold">{{ item.nome }}</h6>
                                </div>

                                <div class="card-body text-left ">
                                    <div class="card-body-plano" v-if="item.servicos.length > 0">
                                        <h6 class="font-weight-bold">Benefícios</h6>
                                        <div class="plano-texto">
                                            <ul style="font-size: 11px;">
                                                <li class="descricao-beneficios" v-for="servico of item.servicos" :key="servico.itself">
                                                    <span>{{ servico.titulo }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div> 
                                    <div v-else>
                                        <span>Conheça adiante os benefícios desse plano</span>
                                    </div>
                                </div>

                                <div class="card-footer border-none">
                                    <div class="row">
                                        <div class="col-5">
                                            <span class="valor-simbolo">R$</span><span class="valor-plano">{{ item.mensalidade | toCurrency(false) }}</span>
                                        </div>
                                        <div class="col-7 text-right">
                                            <button class="btn btn-rounded btn-escolher-plano font-weight-bold" @click="escolherPlano(item)">Quero este &nbsp; 
                                                <i class="fa-solid fa-arrow-right-long text-white"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
            
        <!-- No plans -->
        <div class="container d-flex justify-content-center align-items-center" v-if="this.planos.length == 0 && !loading">
            <div class="row w-100 mt-3">
                <div class="col-12 w-50">
                    <h5 class="font-weight-semibold">Não encontramos nenhuma proposta para você no momento.</h5>
                    <p class="font-weight-semibold">Entre em contato conosco pelo telefone {{ whatsapp }}.</p>
                </div>
                 <div class="col-12">
                    <img src="/assets/svg/searching.svg" alt="" class="" width="300px" height="300px">
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-between w-100 mt-2 px-3">
            <button :disabled="this.isClicked" :class="{ 'clicked': isClicked }" @click="back()" type="button" name="prev" class="prev left">
                <i class="fa-solid fa-arrow-left-long fa-1x"></i> 
            </button>
        </div>

    </div>

</template>

<script>
import LoadingDivComp from "@/components/parts/LoadingDivComp.vue";
import DefaultEnum from '@/enums/DefaultEnum';
import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import { apiRequest, defaultHeaders } from "@/services/apiService";
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

/* eslint-disable */
export default {
    components: {
        LoadingDivComp,
    },

    props: {
    },

    // watch: {
    //     'aplicativo': function () {

    //         this.rastreador_obrigatorio = false;

    //         if (this.aplicativo && this.plano.rastreador_obrigatorio_app) {
    //             this.rastreador = true;
    //             this.rastreador_obrigatorio = true;
    //         }
    //     },
    //     'plano': function () {
    //         this.opcionais = [];
    //         this.rastreador = this.plano.rastreador;
    //         this.rastreador_obrigatorio = this.plano.rastreador;
    //     },
    // },

    data() {
        return {
            plano: {
                servicos: []
            },
            uniqueServices: [],
            opcionais: [],
            planos: [],
            vazio: false,
            rastreador: false,
            aplicativo: false,
            rastreador_obrigatorio: false,
            propostas: [],
            veiculo_modelo: null,
            whatsapp: process.env.VUE_APP_NUMERO_WHATSAPP,
        }
    },

    methods: {
        back() {
            this.navegacao(DefaultEnum.COMPONENTS.VEICULO, this.lsClienteId);
        },

        escolherPlano(item) {
            this.$secureLS.set('ls_choosed_plan', item)
            setTimeout(() => {
                this.navegacao(DefaultEnum.COMPONENTS.BENEFICIOS, this.lsCotacaoId);
            }, 1000);
        },

        // busca os tipos de veiculo disponiveis.
        async buscaPlanos() {
            await apiRequest({
                url: process.env.VUE_APP_FIVE_API + 'veiculo-cotacao/cotacao/' + this.lsCotacaoId + '/planos',
                headers: {
                    ...defaultHeaders.authFive()
                },
                onSuccess: (response) => {
                    this.planos     = response.data.data;
                    this.propostas  = this.planos;
                    this.stopLoading()


                    if(this.planos.length <= 0){
                        this.vazio = true;
                    }
                },
                onError: () => {
                    this.stopLoading()
                    this.error = true;
                    this.vazio = true;
                    this.$swal.fire('Opss!', 'Não conseguimos localizar nenhum plano para você no momento. Tente novamente.', 'warning');
                }
            })
        },
    },



    computed: {
        sortedPropostas() {
            // Crie uma cópia da array propostas antes de ordenar
            const propostasOrdenadas = [...this.propostas];

            // Ordena a array pelo menor valor em propostas.mensalidade
            propostasOrdenadas.sort((a, b) => a.mensalidade - b.mensalidade);

            return propostasOrdenadas;
        },
        mensalidade_servico_total() {
            var total = 0;

            this.opcionais.forEach(v => {
                total += v.valor;
            });

            return total;
        },
        
        mensalidade_total() {

            var total = this.propostas.mensalidade;
            total += this.mensalidade_rastreador;
            total += this.mensalidade_servico_total;

            return total ? total : 0;
        },

        adesao_total() {
            return this.propostas.adesao + this.adesao_rastreador;
        },

        adesao_rastreador() {
            if (this.rastreador) {
                return this.propostas.rastreador_adesao;
            }
            return 0;
        },
        mensalidade_rastreador() {
            if (this.rastreador) {
                return this.propostas.rastreador_mensalidade;
            }
            return 0;
        },
        
        propostaLiberada() {

            if (
                this.proposta.veiculo_tipo.id == null
                || this.proposta.marca.id == null
                || this.proposta.ano.id == null
                || this.proposta.modelo.id == null
            ) {
                return false;
            }
            return true;
        },

        propostaVeiculo() {
            return {
                "cliente_id": this.lsClienteId ?? this.$route.query.cotacao_id,
                'tipo_veiculo': this.proposta.veiculo_tipo.id,
                'marca_id': this.proposta.marca.id,
                'ano': this.proposta.ano.id,
                'modelo_id': this.proposta.modelo.id,
            }
        },
    },
    
    async mounted(){
        await this.verificaCotacao().then((response) => {
            this.veiculo_modelo = response.veiculo.modelo;
        }).catch(() => {})
        await this.buscaPlanos();
        this.stopLoading()


        new Swiper(this.$refs.swiper, {
            
            // configure Swiper to use modules
            modules: [Navigation, Pagination],
            
            // Optional parameters
            loop: false,

            // If we need pagination
            pagination: {
                el: '.swiper-pagination',
            },

            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            // And if we need scrollbar
            scrollbar: {
                el: '.swiper-scrollbar',
            },


            // Default parameters
            slidesPerView: 1,
            spaceBetween: 5,
            breakpoints: {
                1200: {
                    slidesPerView: this.planos.length > 1 ? 2 : 1,
                },
            }

        })
    },
}
</script>

<style scoped>
/* .swiper-slide {
    width: auto !important;
} */

.swiper-wrapper {
    padding: 15px 0;
}

/* .swiper-wrapper, .swiper-slide {
    width: 100% !important;
} */

.swiper-pagination {
    z-index: 1;
}

.btn-escolher-plano{
    position: relative;
    z-index: 9 !important;
}

.descricao-color {
    color: #747474ed;
}

.descricao-beneficios {
    margin-left: 12px;
    list-style-type: circle;
    text-transform: lowercase;
}
.descricao-beneficios::first-letter {
    text-transform: uppercase;
}

.descricao-beneficios span {
    margin-left: -4px;
}
.prev.button {
    background-color: rgb(255 255 255);
}

.plano-card 
{
    border-radius: 10px;
    height: 372px;
    width: 320px;
    box-shadow: 0px 0px 33px -20px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 33px -20px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 33px -20px rgba(0,0,0,0.75);
    /* border: 1px solid var(--cor-primaria); */
    color: #333333d4;
    margin: 0 auto;
    /* font-style: italic; */
}

.card-body-plano {
    position: relative;
}

.plano-texto {
    height: 135px;
    max-height: 135px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.opcoes-planos {
    position: relative;
    width: 100%;
    height: 50px;
}

/* .card-header {
    height: 140px;
} */
.card-header, .card-footer {
    background: transparent;
}

.card-footer button {
    color: #fff;
    background-color: var(--cor-primaria);
    text-transform: uppercase;
    font-size: 13px;
}

.valor-simbolo {
    margin-right: 5px;
    color: var(--cor-primaria);
    font-weight: bold;
}
.valor-plano {
    font-size: 20px;
    font-weight: bold;
    margin-left: -5px;
}

.plano-opcao {
    width: 200px;
    height: 25px;
    padding-top: 2px;
    background-color: var(--cor-primaria);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
}

#plano-anterior {
    position: absolute;
    width: 50px;
    left: 0;
}

#plano-seguinte {
    position: absolute;
    width: 50px;
    right: 0;
}

</style>