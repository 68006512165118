<template>
    <div class="row justify-content-center">
        <div class="col-12 w-100">
            <!-- Stepper -->
            <StepperComp />
            <!-- / Stepper -->
            
            <!-- Loading -->
            <LoadingDivComp v-if="loading" />
            <!-- Loading -->

            <!-- Dados -->
            <div v-show="!loading">
                <h5 class="font-weight-bold">Confirme seus dados pessoais e os dados do seu veículo.</h5>
                <p class="value mt-3 mb-3">Campos destacados com <span style="color: red; font-size: 15px;"> * </span> são obrigatórios.</p>

                <br>
                <!-- Dados pessoais -->
                <div>
                    <h6 class="font-weight-bold mt-4">Dados pessoais</h6>

                    <div class="row pb-4">
                        <div class="col-12 col-md-12 px-1">
                            <div class="input-container">
                                <input v-model="cliente.nome_completo" id="nome_completo" class="input" type="text"
                                    placeholder=" " @input="cliente.nome_completo = removeCaracteresEspeciais(cliente.nome_completo)"  />
                                <span class="text-danger obrigatorio" v-if="$v.cliente.nome_completo.$error">O campo nome completo é obrigatório.</span>
                                <label for="nome_completo" class="label-input placeholder">Nome completo <span>*</span></label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 px-1">
                            <div class="input-container">
                                <input v-model="cliente.telefone_principal" id="telefone_principal" v-mask="'55 (##) # #### ####'" class="input phone_with_ddd"
                                    type="tel" placeholder=" " />
                                <span class="text-danger obrigatorio" v-if="$v.cliente.telefone_principal.$error">O campo telefone principal é obrigatório.</span>
                                <label for="telefone_principal" class="label-input placeholder">Telefone principal <span>*</span></label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 px-1">
                            <div class="input-container">
                                <input v-model="cliente.telefone_recado" id="telefone_recado" v-mask="'(##) # #### ####'"
                                    class="input phone_with_ddd" type="tel" />
                                <label for="telefone_recado" class="label-input placeholder">Telefone recado</label>

                            </div>
                        </div>
                        <div class="col-12 col-md-6 px-1">
                            <div class="input-container">
                                <select v-model="cliente.natureza" name="natureza" class="input">
                                    <option v-for="(item, index) in options.natureza" :value="item.value" :key="index">
                                        {{ item.text }}
                                    </option>
                                </select>
                                <label for="natureza" class="label-input placeholder">Natureza <span>*</span></label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 px-1">
                            <div v-if="cliente.natureza === DefaultEnum.FISICA" class="input-container">
                                <input v-model="cliente.cpf" id="cpf" class="input cpf" type="text" placeholder=" "
                                    v-mask="'###.###.###-##'" />
                                <span class="text-danger obrigatorio" v-if="$v.cliente.cpf.$error">O campo CPF é obrigatório.</span>
                                <label for="cpf" class="label-input placeholder">CPF <span>*</span></label>
                            </div>
                            <div v-if="cliente.natureza === DefaultEnum.JURIDICA" class="input-container">
                                <input v-model="cliente.cnpj" id="cnpj" class="input cnpj" type="text" placeholder=" "
                                    v-mask="'##.###.###/####-##'" />
                                <span class="text-danger obrigatorio" v-if="$v.cliente.cnpj.$error">O campo CNPJ é obrigatório.</span>
                                <label for="cnpj" class="label-input placeholder">CNPJ <span>*</span></label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 px-1">
                            <div v-if="cliente.natureza === DefaultEnum.FISICA" class="input-container">
                                <input v-model="cliente.data_nascimento_abertura" id="data_nascimento" class="input date" type="text"
                                    placeholder=" " v-mask="'##/##/####'" />
                                <span class="text-danger obrigatorio" v-if="$v.cliente.data_nascimento_abertura.$error">O campo Data de nasc. é obrigatório.</span>
                                <label for="data_nascimento" class="label-input placeholder">Data de nascimento <span>*</span></label>
                            </div>
                            <div v-if="cliente.natureza == DefaultEnum.JURIDICA" class="input-container" >
                                <input v-model="cliente.data_nascimento_abertura" id="data_abertura" class="input date" type="text"
                                    placeholder=" " v-mask="'##/##/####'" />
                                <span class="text-danger obrigatorio" v-if="$v.cliente.data_nascimento_abertura.$error">O campo Data de abertura é obrigatório.</span>
                                <label for="data_abertura" class="label-input placeholder">Data de abertura <span>*</span></label>
                            </div>
                        </div>
                        <div v-if="cliente.natureza === DefaultEnum.FISICA" class="col-12 col-md-6 px-1">
                            <div class="input-container">
                                <select v-model="cliente.sexo" name="sexo" class="input">
                                    <option v-for="(item, index) in options.sexo" :value="item.value" :key="index">
                                        {{ item.text }}
                                    </option>
                                </select>
                                <label for="sexo" class="label-input placeholder">Sexo <span>*</span></label>
                            </div>
                        </div>
                        <div v-if="cliente.natureza === DefaultEnum.FISICA" class="col-12 col-md-6 px-1">
                            <div class="input-container">
                                <input v-model="cliente.rg" id="rg" class="input" type="text" placeholder=" " maxlength="12" @input="cliente.rg = apenasNumeros(cliente.rg)" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" />
                                <span class="text-danger obrigatorio" v-if="$v.cliente.rg.$error">O campo RG é obrigatório.</span>
                                <label for="rg" class="label-input placeholder">RG <span>*</span></label>
                            </div>
                        </div>
                        <div v-if="cliente.natureza === DefaultEnum.FISICA" class="col-12 col-md-6 px-1">
                            <div class="input-container">
                                <input v-model="cliente.rg_expeditor" id="rg_expeditor" class="input" type="text" placeholder=" " @input="cliente.rg_expeditor = removeCaracteresEspeciais(cliente.rg_expeditor)" />
                                <span class="text-danger obrigatorio" v-if="$v.cliente.rg_expeditor.$error">O campo RG expeditor é obrigatório.</span>
                                <label for="rg_expeditor" class="label-input placeholder">RG expeditor <span>*</span></label>
                            </div>
                        </div>
                        <div v-if="cliente.natureza === DefaultEnum.FISICA" class="col-12 col-md-6 px-1">
                            <div class="input-container">
                                <input v-model="cliente.cnh" id="cnh" class="input" type="text" placeholder=" " maxlength="12" @input="cliente.cnh = apenasNumeros(cliente.cnh)" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" />
                                <span class="text-danger obrigatorio" v-if="$v.cliente.cnh.$error">O campo CNH é obrigatório.</span>
                                <label for="cnh" class="label-input placeholder">CNH <span>*</span></label>
                            </div>
                        </div>
                        <div v-if="cliente.natureza === DefaultEnum.FISICA" class="col-12 col-md-6 px-1">
                            <div class="input-container">
                                <input v-model="cliente.cnh_vencimento" id="cnh_vencimento" class="input date" type="text" placeholder=" "
                                    v-mask="'##/##/####'" />
                                <span class="text-danger obrigatorio" v-if="$v.cliente.cnh_vencimento.$error">O campo CNH Vencimento é obrigatório.</span>
                                <label for="cnh_vencimento" class="label-input placeholder">CNH vencimento <span>*</span></label>
                            </div>
                        </div>
                        <div v-if="cliente.natureza === DefaultEnum.FISICA" class="col-12 col-md-6 px-1">
                            <div class="input-container">
                                <select v-model="cliente.estado_civil" name="estado_civil" class="input">
                                    <option v-for="(item, index) in options.estado_civil" :value="item.value" :key="index">
                                        {{ item.text }}
                                    </option>
                                </select>
                                <label for="estado_civil" class="label-input placeholder">Estado Civil <span>*</span></label>
                            </div>
                        </div>
                        <div v-if="cliente.natureza === DefaultEnum.FISICA" class="col-12 col-md-6 px-1">
                            <div class="input-container">
                                <input v-model="cliente.profissao" id="profissao" class="input" type="text" placeholder=" " @input="cliente.profissao = removeCaracteresEspeciais(cliente.profissao)" />
                                <span class="text-danger obrigatorio" v-if="$v.cliente.profissao.$error">O campo profissão é obrigatório.</span>
                                <label for="profissao" class="label-input placeholder">Profissão <span>*</span></label>
                            </div>
                        </div>
                        <div :class="cliente.natureza === DefaultEnum.FISICA ? 'col-12 col-md-6 px-1' : 'col-12 col-md-6 px-1'">
                            <div class="input-container">
                                <input v-model="cliente.email" id="email" class="input" type="email" placeholder=" " />
                                <span class="text-danger obrigatorio" v-if="$v.cliente.email.$error">O campo email é obrigatório.</span>
                                <label for="email" class="label-input placeholder">E-mail <span>*</span></label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 px-1">
                            <div class="input-container">
                                <input v-model="cliente.email_alternativo" id="email_alternativo" class="input" type="email" />
                                <label for="email_alternativo" class="label-input placeholder">E-mail alternativo</label>
                            </div>
                        </div>
                    </div>


                    <h6 class="font-weight-bold">Endereço</h6>
                    <div class="divider"></div>


                    <div class="row mt-2 pt-2">
                        <div class="col-12 col-md-3 px-1">
                            <div class="input-container">
                                <input @change="buscaCep($event)" v-model="cliente.endereco.cep" name="cep" type="text" placeholder=" " id="cep"
                                    class="input cep" v-mask="'#####-###'" value="" size="10" maxlength="9" />
                                <span class="text-danger obrigatorio" v-if="$v.cliente.endereco.cep.$error">O campo CEP é obrigatório.</span>
                                <label for="cep" class="label-input placeholder">CEP <span>*</span></label>
                            </div>
                        </div>
                        <div :class="cliente.natureza === DefaultEnum.FISICA ? 'col-12 col-md-3 px-1' : 'col-12 col-md-3 px-1'">
                            <div class="input-container">
                                <input v-model="cliente.endereco.uf" name="uf" type="text" placeholder=" " id="uf" class="input"
                                    size="2" @input="cliente.uf = removeCaracteresEspeciais(cliente.uf)"/>
                                <span class="text-danger obrigatorio" v-if="$v.cliente.endereco.uf.$error">O campo Estado é obrigatório.</span>
                                <label for="uf" class="label-input placeholder">UF <span>*</span></label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 px-1">
                            <div class="input-container">
                                <input v-model="cliente.endereco.logradouro" name="rua" type="text" placeholder=" " id="rua"
                                    class="input" size="60" @input="cliente.endereco.logradouro = removeCaracteresEspeciais(cliente.endereco.logradouro)" />
                                <span class="text-danger obrigatorio" v-if="$v.cliente.endereco.logradouro.$error">O campo rua é obrigatório.</span>
                                <label for="rua" class="label-input placeholder">Logradouro <span>*</span></label>
                            </div>
                        </div>
                        <div :class="cliente.natureza === DefaultEnum.FISICA ? 'col-12 col-md-6 px-1' : 'col-12 col-md-6 px-1'">
                            <div class="input-container">
                                <input v-model="cliente.endereco.bairro" name="bairro" type="text" placeholder=" " id="bairro"
                                    class="input" size="40" @input="cliente.endereco.bairro = removeCaracteresEspeciais(cliente.endereco.bairro)" />
                                <span class="text-danger obrigatorio" v-if="$v.cliente.endereco.bairro.$error">O campo bairro é obrigatório.</span>
                                <label for="bairro" class="label-input placeholder">Bairro <span>*</span></label>
                            </div>
                        </div>
                        <div :class="cliente.natureza === DefaultEnum.FISICA ? 'col-12 col-md-6 px-1' : 'col-12 col-md-6 px-1'">
                            <div class="input-container">
                                <input v-model="cliente.endereco.cidade" name="cidade" type="text" placeholder=" " id="cidade"
                                    class="input" size="40" @input="cliente.endereco.cidade = removeCaracteresEspeciais(cliente.endereco.cidade)" />
                                <span class="text-danger obrigatorio" v-if="$v.cliente.endereco.cidade.$error">O campo cidade é obrigatório.</span>
                                <label for="cidade" class="label-input placeholder">Cidade <span>*</span></label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 px-1">
                            <div class="input-container">
                                <input v-model="cliente.endereco.complemento" id="complemento" class="input" type="text"
                                    placeholder=" " @input="cliente.endereco.complemento = removeCaracteresEspeciais(cliente.endereco.complemento)" />
                                <label for="complemento" class="label-input placeholder">Complemento</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 px-1">
                            <div class="input-container">
                                <input v-model="cliente.endereco.numero" id="numero" class="input" type="text" placeholder=" " maxlength="5"  @input="cliente.endereco.numero = apenasNumeros(cliente.endereco.numero)" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" />
                                <label for="numero" class="label-input placeholder">Número</label>
                            </div>
                        </div>
                    </div>
                </div>
                <!--  -->


                <!-- Dados do veículo -->
                <div class="mt-2 pt-2">
                    <h6 class="font-weight-bold">Veículo</h6>
                    <div class="divider"></div>

                    <div class="row mt-2">
                        <div class="col-lg-3 col-md-6 col-sm-12 p-1">
                            <div class="input-container">
                                <select v-model="veiculo.veiculo_tipo" name="veiculo-tipo" class="input">
                                    <option disabled value="">Selecione o tipo de veículo</option>
                                    <option v-for="tipo in veiculo_tipos" :key="tipo.id" :value="tipo.id" :selected="tipo.id == veiculo.veiculo_tipo">
                                        {{ tipo.titulo }}
                                    </option>
                                </select>
                                <span class="text-danger obrigatorio" v-if="$v.veiculo.veiculo_tipo.$error">O campo veículo é obrigatório.</span>
                                <label for="veiculo_tipo" class="label-input placeholder">Tipo<span>*</span></label>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 p-1">
                            <div class="input-container">
                                <select v-model="veiculo.cambio" name="cambio" class="input">
                                    <option value="manual">Manual</option>
                                    <option value="automatico">Automático</option>
                                </select>
                                <span class="text-danger obrigatorio" v-if="$v.veiculo.cambio.$error">O campo câmbio é obrigatório.</span>
                                <label for="chassi" class="label-input placeholder">Câmbio <span>*</span></label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 p-1">
                            <div class="input-container">
                                <input v-mask="['AAA-#X##']" class="input" v-model="veiculo.placa" name="placa" type="text" placeholder=" " />
                                <span class="text-danger obrigatorio" v-if="$v.veiculo.placa.$error">O campo placa é obrigatório.</span>
                                <label for="placa" class="label-input placeholder">Placa <span>*</span></label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 p-1">
                            <div class="input-container">
                                <input class="input" type="text" name="chassi" v-model="veiculo.chassi" placeholder=" " maxlength="20" @input="veiculo.chassi = removeCaracteresEspeciais(veiculo.chassi)" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" />
                                <span class="text-danger obrigatorio" v-if="$v.veiculo.chassi.$error">O campo chassi é obrigatório.</span>
                                <label for="chassi" class="label-input placeholder">Chassi <span>*</span></label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 p-1">
                            <div class="input-container">
                                <input class="input" type="text" name="renavam" v-model="veiculo.renavam" placeholder=" " maxlength="13" @input="veiculo.renavam = apenasNumeros(veiculo.renavam)" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"/>
                                <span class="text-danger obrigatorio" v-if="$v.veiculo.renavam.$error">O campo renavam é obrigatório.</span>
                                <label for="renavam" class="label-input placeholder">Renavam <span>*</span></label>
                            </div>
                        </div>
                    </div> 
                </div>
                <!--  -->
            </div>
        </div>

        <!--  -->
        <div class="d-flex justify-content-between w-100 mt-4 px-3">
            <button :disabled="this.isClicked" :class="{ 'clicked': isClicked }" @click="back()" type="button" name="prev" class="prev left">
                <i class="fa-solid fa-arrow-left-long fa-1x"></i> 
            </button>

            <button :disabled="this.isClicked" :class="{ 'clicked': isClicked }" @click="confirmacaoDados()" type="button" name="next" class="next right">
                <i class="fa-solid fa-arrow-right-long fa-1x"></i>
            </button>
        </div>
        <!--  -->
        
    </div>
</template>

<script>
import DefaultEnum from '@/enums/DefaultEnum';
import LoadingDivComp from '../parts/LoadingDivComp.vue';
import StepperComp from "../parts/StepperComp.vue";
import { required } from 'vuelidate/lib/validators';
import requiredIf from 'vuelidate/lib/validators/requiredIf';
import { apiRequest, defaultHeaders } from '@/services/apiService';

/* eslint-disable */
export default {
    components: { 
        StepperComp,
        LoadingDivComp
    },

    props: {
    },

    watch: {
        "cliente.cpf": function () {
            if (this.cliente.cpf.length == 14) {
                this.validarCpf(this.cliente.cpf);
            }
        },
        "cliente.endereco.cep": function(){
            this.cliente.endereco.cep.replace('-', '');
        }
    },

    data() {
        return {
            cliente: {
                id: null,
                nome_completo: null,
                cpf: null,
                cnpj: null,
                email: null,
                telefone_principal: null,
                telefone_recado: null,
                data_nascimento_abertura: null,
                estado_civil: null,
                sexo: null,
                rg: null,
                rg_expeditor: null,
                cnh: null,
                cnh_vencimento: null,
                profissao: null,
                email_alternativo: null,
                natureza: DefaultEnum.FISICA,
                endereco: {
                    cep: null,
                    logradouro: null,
                    numero: null,
                    complemento: null,
                    bairro: null,
                    cidade: null,
                    uf: null,
                }
            },
            veiculo_tipos: {},
            veiculo: {
                veiculo_tipo: null,
                cambio: null,
                placa: null,
                chassi: null,
                renavam: null
            },
            options: {
                natureza: [
                    { text: "Pessoa Física", value: DefaultEnum.FISICA },
                    { text: "Pessoa Jurídica", value: DefaultEnum.JURIDICA }
                ],
                sexo: [
                    { text: "Masculino", value: "masculino" },
                    { text: "Feminino", value: "feminino" }
                ],
                estado_civil: [
                    { text: "Solteiro", value: "solteiro" },
                    { text: "Casado", value: "casado" },
                    { text: "Divorciado", value: "divorciado" },
                    { text: "Viúvo", value: "viuvo" },
                    { text: "Separado", value: "Separado" }
                ],
            },
            placa: "",
        };
    },

    validations() {
        return {
            cliente: {
                nome_completo: { required },
                email: { required },
                cpf: { required: requiredIf(function(){ return this.cliente.natureza == DefaultEnum.FISICA } )},
                cnpj: { required: requiredIf(function(){ return this.cliente.natureza == DefaultEnum.JURIDICA } )},
                rg: { required: requiredIf(function(){ return this.cliente.natureza == DefaultEnum.FISICA } )},
                rg_expeditor: requiredIf(function (){ return this.cliente.rg_expeditor == DefaultEnum.FISICA }),
                telefone_principal: { required },
                data_nascimento_abertura: { required },
                cnh: { required },
                cnh_vencimento: { required },
                profissao: { required },
                endereco: {
                    cep: { required },
                    logradouro: { required },
                    bairro: { required },
                    cidade: { required },
                    uf: { required },
                }
            },
            veiculo: {
                veiculo_tipo: { required },
                cambio: { required },
                placa: { required },
                chassi: { required },
                renavam: { required }
            },
        }
    },

    methods: {
        back() {
            if(!this.$secureLS.get('ls_choosed_plan')){
                this.navegacao(DefaultEnum.COMPONENTS.PLANOS, this.lsCotacaoId);
                return
            }

            this.setCurrentComp(DefaultEnum.COMPONENTS.BENEFICIOS, this.lsCotacaoId);
        },

        // busca cep e preenche endereço
        async buscaCep(event) {
            let cep = event.target.value ?? this.cliente.endereco.cep;

            await apiRequest({
                url: `https://viacep.com.br/ws/${cep}/json/`,
                method: 'get',
                onSuccess: (response) => {
                    if (response.data.erro == true) {
                        this.alert("Oops...", "CEP não localizado na busca automática. Digite seu endereço manualmente.", "error");
                        this.limpaEndereco();
                    }

                    this.cliente.endereco.logradouro = response.logradouro;
                    this.cliente.endereco.bairro     = response.bairro;
                    this.cliente.endereco.cidade     = response.localidade;
                    this.cliente.endereco.uf         = response.uf;
                },
                onError: () => {
                    this.alert("Oops...", "Ocorreu um erro ao tentar buscar o CEP. Tente novamente.", "error");
                    this.limpaEndereco();
                    // if (response.data.erro == true) {
                }
            })
        },

        limpaEndereco(){
            this.cliente.endereco.logradouro    = "";
            this.cliente.endereco.numero        = "";
            this.cliente.endereco.complemento   = "";
            this.cliente.endereco.bairro        = "";
            this.cliente.endereco.cidade        = "";
            this.cliente.endereco.uf            = "";
        },

        // ETAPA 3 - atualiza dados do cliente
        async confirmacaoDados() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$swal.fire('Opss!', 'Preencha todos os campos corretamente', 'warning');
                return;
            }

            this.clicked();
            await apiRequest({
                url: process.env.VUE_APP_FIVE_API + "cliente/update",
                headers: {
                    ...defaultHeaders.authFive()
                },
                method: DefaultEnum.METHODS.POST,
                data: this.clienteUpdate,
                onSuccess: () => {
                    this.veiculoConfirmacao();
                },
                onError: (error) => {
                    this.isClicked = DefaultEnum.NAO;
                    this.validarRetorno("Não foi possível atualizar os seus dados pessoais", error.response.data);
                }
            })
        },
        // busca os tipos de veiculo disponiveis.
        async buscaTiposVeiculo() {
            await apiRequest({
                url: process.env.VUE_APP_FIVE_API + "veiculo/tipo",
                headers: {
                    ...defaultHeaders.authFive()
                },
                onSuccess: (response) => {
                    this.veiculo_tipos = response.data.data;
                }
            })
        },
        async veiculoConfirmacao(){
            this.iniLoading();
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.isClicked = false;
                this.alert('', 'Preencha todos os campos corretamente', 'warning')
                return;
            }
            
            if(this.lsCotacao.veiculo.id){
                await apiRequest({
                    url: process.env.VUE_APP_FIVE_API + "veiculo-cotacao/veiculo/" + this.lsCotacao.veiculo.id,
                    headers: {
                        ...defaultHeaders.authFive()
                    },
                    method: DefaultEnum.METHODS.PUT,
                    data: this.veiculoUpdate,
                    onSuccess: () => {
                        this.navegacao(DefaultEnum.COMPONENTS.PAGAMENTO, this.lsCotacaoId);
                    },
                    onError: (error) => {
                        this.isClicked = DefaultEnum.NAO;
                        this.validarRetorno("Não foi possível atualizar os dados do seu veículo", error.response.data);
                        this.stopLoading();
                    }
                })
            }
        },

    },
    computed: {
        // atualização de dados do cliente
        clienteUpdate() {
            return {
                "id": this.lsClienteId,
                "nome_razao": this.cliente.nome_completo,
                "cpf_cnpj": this.cliente.natureza === "fisica" ? this.limpaString(this.cliente.cpf) : this.limpaString(this.cliente.cnpj),
                "email": this.cliente.email,
                "telefone_principal": this.limpaString(this.cliente.telefone_principal),
                "telefone_recado": this.limpaString(this.cliente.telefone_recado),
                "natureza": this.cliente.natureza ?? "fisica",
                "data_nascimento_abertura": this.converteData(this.cliente.data_nascimento_abertura),
                "sexo": this.cliente.sexo,
                "rg": this.cliente.rg,
                "rg_expeditor": this.cliente.rg_expeditor,
                "cnh": this.cliente.cnh,
                "cnh_vencimento": this.converteData(this.cliente.cnh_vencimento),
                "estado_civil": this.cliente.estado_civil,
                "profissao": this.cliente.profissao,
                "email_alternativo": this.cliente.email_alternativo,
                "endereco": {
                    "logradouro": this.cliente.endereco.logradouro,
                    "numero": parseInt(this.cliente.endereco.numero, 10),
                    "bairro": this.cliente.endereco.bairro,
                    "complemento": this.cliente.endereco.complemento,
                    "cep": this.cliente.endereco.cep.replace('-', ''),
                    "cidade": this.cliente.endereco.cidade,
                    "uf": this.cliente.endereco.uf,
                }
            };
        },
        veiculoUpdate(){
            return {
                "tipo": this.veiculo.veiculo_tipo.id,
                "cambio": this.veiculo.cambio,
                "placa": this.veiculo.placa,
                "chassi": this.veiculo.chassi,
                "renavan": this.veiculo.renavam
            }
        },
    },

    async mounted() {
        this.verificaCotacao().then((responseCotacao) => {
            this.valorMensalidade     =  responseCotacao.protecao_mensalidade;
            this.veiculo.cambio       =  this.veiculo.cambio || responseCotacao.veiculo.cambio;
            this.veiculo.chassi       =  responseCotacao.veiculo.chassi ?? this.veiculo.chassi;
            this.veiculo.renavam      =  this.veiculo.renavam || responseCotacao.veiculo.renavan;
            this.veiculo.placa        =  responseCotacao.veiculo.placa ?? this.veiculo.placa;
            this.veiculo.veiculo_tipo =  responseCotacao.veiculo.tipo ?? this.veiculo.veiculo_tipo;          
        }).catch(() => {})
        

        this.verificaCliente(this.lsCotacao.veiculo.cliente.id)
        .then((responseCliente) => {
            this.cliente = responseCliente;
            this.cliente.natureza = this.lsCotacao.veiculo.cliente.natureza ?? DefaultEnum.FISICA;
        }).catch(() => {})

        await this.buscaTiposVeiculo();
        this.stopLoading()
    },

}
</script>